import * as React from "react";
import {useState,useEffect} from 'react'
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { BrowserRouter as Router, Routes, Route, BrowserRouter} from "react-router-dom";
import Home from "../home/Home";
import About from "../about/About";
import PrediccionRA from "../prediccion_ra/PrediccionRA";
import SpreadGtm from "../spread_gtm/SpreadGtm";
import MapeFront from "../mape_front/MapeFront";
import MapeClients from "../mape_clients/MapeClients";
import MapeMedidor from "../mape_medidor/MapeMedidor";
import InfoCenter from "../info_center/InfoCenter";
import MapeContraparte from "../mape_contraparte/Mape_Contraparte";
import PronosticoGens from "../pronostico_gens/Pronostico_gens";
import PronosticoFront from "../pronostico_front/Pronostico_front";
import "../../styles/Menu.css"
import logo from "../../images/logo_cfecal.png"
import help from "../../images/Ayuda.png"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from "axios";

export default function ButtonAppBar() {

  const url = `${process.env.REACT_APP_URL_BACK}`
  const [auth, setAuth] = useState(false)
  
  useEffect(() => {
    if(sessionStorage.getItem("token") != null){
      setAuth(true)
    }
    else if(sessionStorage.getItem("token") === "") {
      setAuth(false)
    }
  },[])


  const logout = (event) => {
    event.preventDefault();
    const token = sessionStorage.getItem("token")
    const config = {
      headers: {
        Authorization: "token " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
        
      },
    };
    axios.post(
      url + "servicio/users",
      {},
      config
    ).then((response) =>{
      sessionStorage.clear();
      setAuth(false);
      window.location.replace("/");
    }).catch((e)=>{
      console.log("No se pudo cerrar sesión, revise su conexión a internet")
    })
  }

  function HomeRouter(){
    const h = <Home auth={auth} isAuth={setAuth}/>
    return(
      <BrowserRouter basename="/">
        <Routes>
          <Route exact path="/"       element={ h                             }/>
          <Route exact path="/pred_ra"      element={ <PrediccionRA auth={auth} />}/>
          <Route exact path="/spread_gtm"   element={ <SpreadGtm auth={auth}/>      }/>
          <Route exact path="/mape_front"   element={ <MapeFront auth={auth}/>      }/>
          <Route exact path="/mape_clients" element={ <MapeClients auth={auth}/>    }/>
          <Route exact path="/mape_medidor" element={ <MapeMedidor auth={auth}/>    }/>
          <Route exact path="/info_center"  element={  <InfoCenter auth={auth}/>     }/>
          <Route exact path="/about"        element={ <About/>                      }/>
          <Route exact path="/pron_gens"    element={ <PronosticoGens auth={auth}/> }/>
          <Route exact path="/pron_front"   element={ <PronosticoFront auth={auth}/> }/>
          <Route exact path="/mape_con"     element={ <MapeContraparte auth={auth}/> }/>
          <Route exact path="*"             element={ h                             }/>
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <>
    { auth ? (
        <>
          <Box sx={{ flexGrow: 10 }}>
            <AppBar className="toolbar" position="static">
                <Toolbar className="cont">
                  <img src={logo}/>
                  <div>
                    <Button className="logout" onClick={logout}><ArrowBackIcon className="arrow"/> Salir</Button>
                    <IconButton><img src={help}/></IconButton>
                  </div>
                </Toolbar>
            </AppBar>
            <AppBar position="static">
              <Toolbar className="title">
                <Typography  variant="h6">
                  Servicios de gestión y energía
                </Typography >
              </Toolbar>
            </AppBar>
          </Box>
          <HomeRouter/>
        </>

        ) : (
        <>
          <Box>
            <AppBar className="toolbar" position="static">
              <Toolbar className="cont">
                <img src={logo}/>
                <IconButton><img src={help}/></IconButton>
              </Toolbar>
              
            </AppBar>
            <AppBar position="static">
              <Toolbar className="title">
                <Typography variant="h6">
                  Servicios de gestión y energía
                </Typography>
              </Toolbar>
            </AppBar>
          </Box>
          <HomeRouter/>  
        </>
        )
      }
    </>
    )
}