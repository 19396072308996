import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import axios, { AxiosError } from "axios";
import CircularProgress from "@mui/material/CircularProgress";

const sLoad = {
  opacity:"100%",
  display: "flex",
  position: "absolute",
  justifyContent: "center",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 40,
  bgcolor: "background.paper",
  border: "2px",
  boxShadow: 24,
  p: 4,
  color: "black",
};

export default function LoadingModal({ isLoad }) {
  return (
    <>
      <Modal
        open={isLoad}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={sLoad}>
          <CircularProgress />
        </Box>
      </Modal>
    </>
  );
}
