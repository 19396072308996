import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import "dayjs/locale/es-mx";
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Label,
} from "recharts";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingModal from "../widgets/LoadingModal";
import ErrorModal from "../widgets/ErrorModal";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/Mexico_City");

const formStyle = {
  padding: "1em",
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const columns = [
  { field: "fecha", headerName: "Fecha", width: 200 },
  { field: "hora", headerName: "Hora", width: 200 },
  { field: "carga", headerName: "Carga", width: 200 },
  { field: "pronos", headerName: "Pronostico", width: 200 },
  { field: "kwh", headerName: "Kwh", width: 200 },
  { field: "mape", headerName: "Mape", width: 200 },
];

export default function MapeMedidor({ auth }) {
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const handleClose = () => setError(false);
  const [clients, setClients] = useState([]);
  const [values, setValues] = useState({});
  const [data, setData] = useState([]);
  const url = `${process.env.REACT_APP_URL_BACK}`;

  useEffect(() => {
    getClients();
  }, []);

  const getClients = () => {
    setLoad(true);
    const token = sessionStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "token " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    axios
      .get(url + "servicio/mape_medidor", config)
      .then((response) => {
        setClients(response.data.clients);
        setLoad(false);
      })
      .catch((e) => {
        setLoad(false);
        setError(true);
        return null;
      });
  };

  const submit = (event) => {
    setLoad(true);
    event.preventDefault();
    const token = sessionStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "token " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    axios
      .post(url + "servicio/mape_medidor", values, config)
      .then((response) => {
        setData(response.data.data);
        setLoad(false);
      })
      .catch((e) => {
        setLoad(false);
        setError(true);
        return null;
      });
  };

  function onChange(event, type) {
    let v = values;
    if (Object.keys(values).includes(type)) {
      v[type] = event.target.value;
    } else {
      v[type] = event.target.value;
    }
    setValues(v);
  }

  function onChangeCal(event, type) {
    let v = values;
    if (Object.keys(values).includes(type)) {
      v[type] = event.$d.toISOString().slice(0, 10);
    } else {
      v[type] = event.$d.toISOString().slice(0, 10);
    }
    setValues(v);
  }

  function Form() {
    return (
      <form style={{ display: "flex", alignItems: "center" }} onSubmit={submit}>
        <FormControl style={formStyle}>
          <FormLabel>Clientes:</FormLabel>
          <Select defaultValue={-1} onChange={(e) => onChange(e, "client")}>
            <MenuItem key={-1} value={-1}>
              Selecciona una opción{" "}
            </MenuItem>
            {clients.map((item, index) => (
              <MenuItem key={index} value={item["identificador"]}>
                {item["identificador"]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl style={formStyle}>
          <FormLabel>Fecha inicial:</FormLabel>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="es-mx"
          >
            <DatePicker
              format="YYYY-MM-DD"
              onChange={(e) => onChangeCal(e, "fecha-inicio")}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl style={formStyle}>
          <FormLabel>Fecha final:</FormLabel>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="es-mx"
          >
            <DatePicker
              format="YYYY-MM-DD"
              onChange={(e) => onChangeCal(e, "fecha-fin")}
            />
          </LocalizationProvider>
        </FormControl>

        <Button
          color="primary"
          type="submit"
          variant="outlined"
          sx={{ height: "50px" }}
        >
          Calcular
        </Button>
      </form>
    );
  }

  function Graph() {
    return (
      <div>
        <ResponsiveContainer height={500}>
          <ComposedChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="hora" />
            <YAxis>
              <Label
                style={{
                  textAnchor: "middle",
                  fontSize: "100%",
                  fill: "black",
                }}
                angle={270}
                value={"MW"}
              />
            </YAxis>
            <Tooltip />
            <Legend />
            <Line name="Kwh" type="monotone" dataKey="gKwh" stroke="#0000ff" dot={false} />
            <Line
              name="Pronostico"
              type="monotone"
              dataKey="pronos"
              stroke="#FF0000"
              dot={false}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  }

  function Table() {
    return (
      <div style={{ height: 500, width: "100%" }}>
        <DataGrid
          rows={data}
          columns={columns}
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </div>
    );
  }

  return (
    <>
      {auth ? (
        <>
          <Box
            sx={{
              margin: "10px",
              padding: "0 10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Link href="/">
                <IconButton>
                  <ArrowBackIcon />
                </IconButton>
              </Link>
              <h1>Mape clientes/medidor</h1>
            </Box>

            <Form />
            <Graph />
            <Table />
            <LoadingModal isLoad={load} />
            <ErrorModal open={error} close={handleClose} />
          </Box>
        </>
      ) : (
        <>
          {" "}
          <Box
            sx={{
              margin: "10px",
              padding: "0 10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Link href="/">
                <IconButton>
                  <ArrowBackIcon />
                </IconButton>
              </Link>
              <h1>Inicia Sesión</h1>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
