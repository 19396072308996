import axios from "axios";
import React from "react";
import { useState,} from "react";
import {
  Button,
  FormControl,
  FormLabel,
} from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import "dayjs/locale/es-mx";
import { styled } from "@mui/system";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingModal from "../widgets/LoadingModal";
import ErrorModal from "../widgets/ErrorModal";
import Papa from "papaparse"
import SuccessModal from "../widgets/SuccessModal";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/Mexico_City");


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const formStyle = {
  padding: "1em",
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const columns = [
  { field: "fecha", headerName: "Fecha", width: 200 },
  { field: "hora", headerName: "Hora", width: 200 },
  { field: "enlace", headerName: "Enlace", width: 200 },
  { field: "precio_pronosticado", headerName: "Precio pronosticado", width: 200 },
];

export default function PronosticoFront({ auth }) {
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [messageData, setMessageData] = useState([])
  const handleClose = () => setError(false);
  const handleCloseS = () => setSuccess(false);
  const [file, setFile] = useState([]);
  const [values, setValues] = useState({
    dateI : dayjs(new Date()),
    "fecha-inicio":dayjs().subtract(6,"hours").format('YYYY-MM-DD'),
  });
  const [data, setData] = useState([]);
  const url = `${process.env.REACT_APP_URL_BACK}`;



  function parse(){
    return new Promise((resolve, reject) => {
      Papa.parse(file,{
        complete: function(results){
            resolve(results.data)
        },
        error: function(error){
          reject(error)
        }
      })
    });
  }

  async function submit(event){
      event.preventDefault()
      setLoad(true)
      const result = await parse()
      var lista_data = []
      let i = 1
      const e = result.shift()[0]
      result.forEach(element => {
        if (element[0] != ""){
          const tmp = {
            "fecha": values['fecha-inicio'],
            "hora": i,
            "enlace":e,
            "precio": element[0]
          }
          i++;
          lista_data.push(tmp)
        }
      });
      const response = await post("servicio/pron_front/",lista_data)
      
      if (response["Status"] == "Ok"){
        
        const getResponse = await post("servicio/pron_front/get",{'fecha':values['fecha-inicio']})
        if(getResponse["Status"] == "Ok"){
            setLoad(false)
            setMessageData(response["Data"])
            setData(getResponse["Data"])
            setSuccess(true)
        }else{
          setLoad(false)
          setMessageData(response["Data"])
          setError(true)
        }
      }
      else{
        setLoad(false)
        setMessageData(response["Data"])
        setError(true)
      }
      
    }


  const post = async(endpoint,info) =>{
    const token = sessionStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "token " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const response = await axios
    .post(url + endpoint, info, config)
    .then((response) => {
        return response.data
    })
    .catch((e) => {
      console.log(e)
      return e;
    });
    return response
  }

  function onChangeCal(event, type) {
    let v = values;
    v[type] = dayjs(event.$d.toISOString()).format("YYYY-MM-DD")
    if(type == "fecha-inicio")
      v['dateI'] = dayjs(event.$d.toISOString())
    if(type == "fecha-fin")
      v['dateF'] = dayjs(event.$d.toISOString())
    setValues(v);
  }


  function Form() {
    return (
      <form style={{ display: "flex", alignItems: "center" }} onSubmit={submit}>
        <FormControl style={formStyle}>
          <FormLabel>Fecha del archivo:</FormLabel>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="es-mx"
          >
            <DatePicker
              defaultValue={values.dateI}
              format="YYYY-MM-DD"
              onChange={(e) => onChangeCal(e, "fecha-inicio")}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl style={formStyle}>
          <FormLabel>Archivo CSV:</FormLabel>
          <Button
            component= "label"
            role = {undefined}
            variant="outlined"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
            sx={{ height: "50px" }}
          >
            Selecciona tu archivo
            <VisuallyHiddenInput type="file" onChange={(e) => {setFile(e.target.files[0]);} }/>
          </Button>
        </FormControl>

        <FormControl style={formStyle}>
          <br/>
          <Button
            color="primary"
            type="submit"
            variant="outlined"
            sx={{ height: "50px" }}
          >
            Subir archivo
          </Button>
        </FormControl>
        
      </form>
    );
  }

  function Table() {
    return (
      <div style={{ height: 500, width: "100%" }}>
        <DataGrid
          rows={data}
          columns={columns}
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </div>
    );
  }


  return (
    <>
      {auth ? (
        <>
          <Box
            sx={{
              margin: "10px",
              padding: "0 10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Link href="/">
                <IconButton>
                  <ArrowBackIcon />
                </IconButton>
              </Link>
              <h1>Pronostico Front</h1>
            </Box>
            
            <Form/>
            <Table/>
            

            <LoadingModal isLoad={load} />
            <ErrorModal open={error} close={handleClose} data={messageData}/>
            <SuccessModal open={success} close={handleCloseS} data={messageData}/>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              margin: "10px",
              padding: "0 10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Link href="/">
                <IconButton>
                  <ArrowBackIcon />
                </IconButton>
              </Link>
              <h1>Inicia Sesión</h1>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
