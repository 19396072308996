import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  TextField,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import "dayjs/locale/es-mx";
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Area,
} from "recharts";
import { DataGrid, GridToolbarContainer, GridToolbarExport  } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoadingModal from "../widgets/LoadingModal";
import ErrorModal from "../widgets/ErrorModal";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/Mexico_City");

const formStyle={
  padding:'1em'
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const columns = [
  { field: 'hora', headerName: 'Fecha', width: 200 },
  { field: 'poe_usd', headerName: 'POE USD', width: 200 },
  { field: 'pml_usd', headerName: 'PML USD', width: 200 },
  { field: 'pml-poe_usd', headerName: 'PML - POE', width: 200 },
];

export default function SpreadGtm({ auth }) {
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false)
  const handleClose = () => setError(false);

  const nodos = [
    "06RRD-138",
    "06LAA-138",
    "06EAP-138",
    "07IVY-230",
    "07OMS-230",
    "09LBR-230",
    "08XUL-115",
  ];
  const desicion = [
    "Dia previo",
    "Promedio 2 dias anteriores",
    "Promedio Semanal",
    "Dia Anterior Similar",
    "Promedio 2 dias similares",
    "Promedio 4 dias similares",
    "Pronostico ARIMA",
  ];
  const [values, setValues] = useState({ 
    dateI : dayjs(new Date().setDate(new Date().getDate() - 1 )),
    dateF:  dayjs(new Date().setDate(new Date().getDate() - 1 )),
    fecha_i:dayjs(new Date().setDate(new Date().getDate() - 1 )).toISOString().slice(0, 10),
    fecha_f:dayjs(new Date().setDate(new Date().getDate() - 1 )).toISOString().slice(0, 10),
    usd_imp: 25,
    usd_exp: 10 });
  const [data, setData] = useState([]);
  const [dataE, setDataE] = useState([]);
  const url = `${process.env.REACT_APP_URL_BACK}`


  useEffect(() => {
    //getPrediccionRA()
  }, []);

  const submit = (event) => {
    setData([])
    setLoad(true)
    event.preventDefault();
    const token = sessionStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "token " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    axios
      .post(
        url + "servicio/spread_guatemala",
        values,
        config
      )
      .then((response) => {
        if(response.data.Status == 'Error'){
          setLoad(false)
          setDataE(response.data.data)
          setError(true)
        }else{
          setData(response.data.data);
          setLoad(false)
        }
        
      })
      .catch((e) => {
        setLoad(false)
        setError(true)
        return null;
      });
  };

  function onChange(event, type) {
    let v = values;
    if (Object.keys(values).includes(type)) {
      v[type] = event.target.value;
    } else {
      v[type] = event.target.value;
    }
    setValues(v);
  }

  function onChangeCal(event, type) {
    let v = values;
    v[type] = event.$d.toISOString().slice(0, 10);
    if(type == "fecha_i")
      v['dateI'] = dayjs(event.$d.toISOString())
    if(type == "fecha_f")
      v['dateF'] = dayjs(event.$d.toISOString())
  
    setValues(v);
  }

  function Form(){
    return(
      <form style={{display:'flex',alignItems:'center'}} onSubmit={submit}>
        <FormControl style={formStyle} >
          <FormLabel>Fecha inicial:</FormLabel>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="es-mx"
          >
            <DatePicker
              defaultValue={values.dateI}
              format="YYYY-MM-DD"
              onChange={(e) => onChangeCal(e, "fecha_i")}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl style={formStyle}>
          <FormLabel>Fecha final:</FormLabel>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="es-mx"
          >
            <DatePicker
              defaultValue={values.dateF}
              format="YYYY-MM-DD"
              onChange={(e) => onChangeCal(e, "fecha_f")}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl style={formStyle}>
          <FormLabel>Tarifa import en USD:</FormLabel>
          <div>
            <TextField
              variant="outlined"
              aria-label="Demo number input"
              placeholder="Type a number…"
              type="number"
              defaultValue="25"
              onChange={(e) => onChange(e, "usd_imp")}
              InputProps={{ inputProps: { min: 0 } }}
            />
          </div>
        </FormControl>

        <FormControl style={formStyle}>
          <FormLabel>Tarifa export en USD:</FormLabel>
          <div>
            <TextField
              variant="outlined"
              aria-label="Demo number input"
              placeholder="Type a number…"
              type="number"
              defaultValue="10"
              onChange={(e) => onChange(e, "usd_exp")}
              InputProps={{ inputProps: { min: 0 } }}
            />
          </div>
        </FormControl>

        <FormControl>
          <br/>
          <Button color="primary" type='submit' variant='outlined' sx={{height:'50px'}}>
            Calcular
          </Button>
        </FormControl>
      </form>
    )
  }

  function Graph(){
    return(
      <div>
        <ResponsiveContainer height={500}>
          <ComposedChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="hora" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              name="USD Imp"
              type="monotone"
              dataKey="imp_usd"
              stroke="#FF0000"
              dot={false}
            />
            <Line
              name="USD Exp"
              type="monotone"
              dataKey="exp_usd"
              stroke="#FF0000"
              dot={false}
            />
            <Line
              name="PML-POE"
              type="monotone"
              dataKey="pml-poe_usd"
              stroke="#000000"
              dot={false}
            />
            <Area name="USD" dataKey="usd" fill="#FA8072" stroke="#FA8072" />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    )
  }

  function Table(){
    return(
      <div style={{ height: 500, width: "100%" }}>
      <DataGrid 
        rows={data}
        columns={columns}
        slots={{
          toolbar: CustomToolbar
        }}/>
    </div>
    )
  }

  return (
    <>
      {
      auth ? 
      (<>
        <Box sx={{
          margin:'10px',
          padding:'0 10px'
        }}>
          <Box sx={{display:'flex', flexWrap:'wrap', justifyContent:'flex-start', alignItems:'center'}}>
            <Link href='/' ><IconButton><ArrowBackIcon/></IconButton></Link>
            <h1>Spread Guatemala</h1>
          </Box>
  
          <Form/>
          <Graph/>
          <Table/>
          <LoadingModal isLoad={load}/>
          <ErrorModal open={error} close={handleClose} data={dataE}/>
        </Box>
        
      </>)
      :
      (<>
        <Box sx={{
          margin:'10px',
          padding:'0 10px'
        }}>
          <Box sx={{display:'flex', flexWrap:'wrap', justifyContent:'flex-start', alignItems:'center'}}>
            <Link href='/' ><IconButton><ArrowBackIcon/></IconButton></Link>
            <h1>Inicia Sesión</h1>
          </Box>
        </Box>
        
      </>)
    }
    </>
    
  );
}
