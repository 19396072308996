import axios from "axios";
import { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Link,
  MenuItem,
  Select,
  Slider,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import "dayjs/locale/es-mx";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import LoadingModal from "../widgets/LoadingModal";
import ErrorModal from "../widgets/ErrorModal";
import Box from "@mui/material/Box";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";


dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/Mexico_City");

const formStyle = {
  padding: "1em",
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const columns = [
  { field: "hora", headerName: "Hora", width: 200 },
  { field: "lis_dia_1", headerName: "Día anterior", width: 200 },
  { field: "lis_dia_1_s", headerName: "Día anterior similar", width: 200 },
  { field: "lis_dia_2", headerName: "Promedio 2 días anteriores", width: 200 },
  {
    field: "lis_dia_2_s",
    headerName: "Promedio 2 días anteriores similares",
    width: 250,
  },
  {
    field: "lis_dia_4_s",
    headerName: "Promedio 4 días anteriores similares",
    width: 250,
  },
  { field: "lis_semana", headerName: "Promedio semanal", width: 200 },
  { field: "prediccion", headerName: "Pronostico ARIMA", width: 200 },
];

export default function PrediccionRa({ auth }) {
  const [load, setLoad] = useState(false);
  const nodos = [
    "06RRD-138",
    "06LAA-138",
    "06EAP-138",
    "07IVY-230",
    "07OMS-230",
    "09LBR-230",
    "08XUL-115",
    "08BEL-115",
  ];
  const desicion = [
    "Dia previo",
    "Promedio 2 dias anteriores",
    "Promedio Semanal",
    "Dia Anterior Similar",
    "Promedio 2 dias similares",
    "Promedio 4 dias similares",
    "Pronostico ARIMA",
  ];
  const [values, setValues] = useState(
    { percent: 0,
      nodo_id: -1,
      dateI : dayjs(new Date() ),
      dateF: dayjs(new Date().setDate(new Date().getDate() - 30)),
      fecha_pronostico: dayjs(new Date() ).toISOString().slice(0, 10),
      fecha_aux: dayjs(new Date().setDate(new Date().getDate() - 30)).toISOString().slice(0, 10),
      option: -1
    }
    );
  const [data, setData] = useState([]);
  const [max, setMax] = useState('');
  const url = `${process.env.REACT_APP_URL_BACK}`;
  const [error, setError] = useState(false);
  const handleClose = () => setError(false);

  useEffect(() => {
    //getPrediccionRA()
  }, []);

  const submit = (event) => {
    setData([])
    setLoad(true)
    event.preventDefault();
    const token = sessionStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "token " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    axios
      .post(url + "servicio/prediccionAR/", values, config)
      .then((response) => {
        setData(response.data.data);
        setLoad(false)
      })
      .catch((e) => {
        setData([]);
        setLoad(false);
        setError(true);
        return null;
      });
  };

  function getMaxPml(nodo){
    setLoad(true)
    setData([])
    const token = sessionStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "token " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    axios
      .post(url + "servicio/prediccionAR/max",{"nodo":nodo}, config)
      .then((response) => {
        setMax('Ultima actualización del nodo "0'+ nodo + '": ' + response.data.max)
        setLoad(false)
      })
      .catch((e) => {
        setLoad(false);
        setError(true);
        return '';
      });
  }

  function onChange(event, type) {
    let v = values;
    if (Object.keys(values).includes(type)) {
      v[type] = event.target.value;
    } else {
      v[type] = event.target.value;
    }
    if(type == "nodo"){
      setMax('')
      v["nodo_id"] = event.target.value
      getMaxPml(event.target.value)
    }
    setValues(v);
  }



  function onChangeCal(event, type) {
    let v = values;
    v[type] = event.$d.toISOString().slice(0, 10);

    if(type == "fecha_pronostico")
      v['dateI'] = dayjs(event.$d.toISOString())
    if(type == "fecha_aux")
      v['dateF'] = dayjs(event.$d.toISOString())
    
    setValues(v);
  }

  function Form() {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
      <form style={{ display: "flex", alignItems: "center" }} onSubmit={submit}>
        <FormControl style={formStyle}>
          <FormLabel>Calcular precios para:</FormLabel>
          <Select defaultValue={values.nodo_id} onChange={(e) => onChange(e, "nodo")}>
            <MenuItem key={-1} value={-1}>
              Selecciona un nodo{" "}
            </MenuItem>
            {nodos.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl style={formStyle}>
          <FormLabel>Fecha para el pro/nostico:</FormLabel>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="es-mx"
          >
            <DatePicker
              defaultValue={values.dateI}
              format="YYYY-MM-DD"
              onChange={(e) => onChangeCal(e, "fecha_pronostico")}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl style={formStyle}>
          <FormLabel>Fecha al menos un mes atras:</FormLabel>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="es-mx"
          >
            <DatePicker
              defaultValue={values.dateF}
              format="YYYY-MM-DD"
              onChange={(e) => onChangeCal(e, "fecha_aux")}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl style={formStyle}>
          <FormLabel>Determino que se parece a:</FormLabel>
          <Select defaultValue={values.option} onChange={(e) => onChange(e, "option")}>
            <MenuItem key={-1} value={-1}>
              Selecciona una opcion{" "}
            </MenuItem>
            {desicion.map((item, index) => (
              <MenuItem key={index} value={index}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl style={formStyle}>
          <FormLabel>Agregar procentaje:</FormLabel>
          <Slider
            defaultValue={values.percent}
            min={-40}
            max={40}
            step={1}
            valueLabelDisplay="auto"
            onChange={(e) => onChange(e, "percent")}
          />
        </FormControl>
      
      <FormControl>
        <br/>
        <Button
          color="primary"
          type="submit"
          variant="outlined"
          sx={{ height: "50px" }}
        >
          Calcular
        </Button>
      </FormControl>

      </form>
        <div style={{padding:'1em'}}>
          {max}
        </div>
      </div>
    );
  }

  function Graph() {
    return (
      <div>
        <ResponsiveContainer height={500}>
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="hora" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              name="Día anterior"
              type="monotone"
              dataKey="lis_dia_1"
              stroke="#0000FF"
            />
            <Line
              name="Dia anterior similar"
              type="monotone"
              dataKey="lis_dia_1_s"
              stroke="#FF0000	"
            />
            <Line
              name="Promedio 2 días anteriores"
              type="monotone"
              dataKey="lis_dia_2"
              stroke="#008000"
            />
            <Line
              name="Promedio semanal"
              type="monotone"
              dataKey="lis_semana"
              stroke="#FFA500"
            />
            <Line
              name="Promedio 2 días similares"
              type="monotone"
              dataKey="lis_dia_2_s"
              stroke="#000000"
            />
            <Line
              name="Promedio 4 días similares"
              type="monotone"
              dataKey="lis_dia_4_s"
              stroke="#A52A2A"
            />
            <Line
              name="Pronostico ARIMA"
              type="monotone"
              dataKey="prediccion"
              stroke="	#800080"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }

  function Table() {
    return (
      <div style={{ height: 500, width: "100%" }}>
        <DataGrid
          rows={data}
          columns={columns}
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </div>
    );
  }

  return (
    <>
      {auth ? (
        <>
          <Box
            sx={{
              margin: "10px",
              padding: "0 10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Link href="/">
                <IconButton>
                  <ArrowBackIcon />
                </IconButton>
              </Link>
              <h1>Enlaces internacionales</h1>
            </Box>

            <Form />
            <Graph />
            <Table />
            <LoadingModal isLoad={load} />
            <ErrorModal open={error} close={handleClose} />
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              margin: "10px",
              padding: "0 10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Link href="/">
                <IconButton>
                  <ArrowBackIcon />
                </IconButton>
              </Link>
              <h1>Inicia Sesión</h1>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
