import ReactDOM from "react-dom/client";
import ResponsiveAppBar from "./components/toolbar/ButtonAppBar";
import { ThemeProvider, createTheme } from "@mui/material/styles"

const theme = createTheme({
  palette: {
    primary:{
      main:'#30694F'
    },
    secondary:{
      main: '#4EBA74'
    }
  }
})

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
    <ThemeProvider theme={theme}>
      <ResponsiveAppBar/>
    </ThemeProvider>
    
  </>
)

