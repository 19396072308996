import * as React from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import "../../styles/ErrorModal.css"
import error from "../../images/error.png"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  max_width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  color: "black",
};

export default function SuccessModal({ open,close,data=[] }) {
    return (
      <div>
        <Modal
          open={open}
          //onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div sx={style} className="box">
            <img className="img" src={error} alt="No"/>
            
            <div id="message-error">
              {data.map((item,index) =>(
                <p id={index} key={index}>
                  {item.exito}
                </p>
              ))}
            </div>
            <div>
              <Button  className="button" variant="contained" onClick={close}>Cerrar</Button>
            </div>
          </div>
        </Modal>
      </div>
    );
}
