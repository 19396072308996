import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import "dayjs/locale/es-mx";
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
} from "recharts";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingModal from "../widgets/LoadingModal";
import ErrorModal from "../widgets/ErrorModal";
import 'dayjs/locale/es-mx';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("es-mx")
dayjs.tz.setDefault("America/Mexico_City");

const formStyle = {
  padding: "1em",
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const columns = [
  { field: "fecha", headerName: "Fecha", width: 200 },
  { field: "hora", headerName: "Hora", width: 200 },
  { field: "carga", headerName: "Carga", width: 200 },
  { field: "pronos", headerName: "Pronostico", width: 200 },
  { field: "mwh", headerName: "Mwh", width: 200 },
  //{ field: "mape", headerName: "Mape", width: 200 },
];

const general = [
  { field: "mwh", headerName: "Mwh", width: 200 },
  { field: "pronostico", headerName: "Totalizador consumo-pronostico por hora", width: 400 },
  { field: "mape", headerName: "Mape", width: 200 }
];

const columns_e = [
  { field: "fecha_inicio", headerName: "Fecha Inicial", width: 200 },
  { field: "fecha_final", headerName: "Fecha Final", width: 200 },
  { field: "hora_inicio", headerName: "Hora Inicial", width: 200 },
  { field: "hora_final", headerName: "Hora Final", width: 200 },
  { field: "cliente", headerName: "Cliente", width: 200 },
  { field: "descripcion", headerName: "Descripción", width: 200 },
  { field: "usuario", headerName: "Usuario", width: 200 },
  { field: "libranza_evento", headerName: "Libranza Evento", width: 200 },
  { field: "programa", headerName: "Programa", width: 200 },
];

const fechaActual = new Date().toLocaleDateString().split("/")
let current = new Date()
let fecha1Mes = current.setMonth(current.getMonth() - 1) 
export default function MapeClients({ auth }) {
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const handleClose = () => setError(false);
  const [clients, setClients] = useState([]);
  const [values, setValues] = useState({
    dateI : dayjs(new Date().setDate(new Date().getDate() - 30) ),
    dateF: dayjs(new Date()),
    "fecha-inicio": dayjs().subtract(6,"hours").subtract(1,"month").format('YYYY-MM-DD'),
    "fecha-fin": dayjs().subtract(6,"hours").format('YYYY-MM-DD'),
    client: -1
  });
  const [data, setData] = useState([]);
  const [events, setEvents] = useState([]);
  const [mape, setMape] = useState([]);
  const url = `${process.env.REACT_APP_URL_BACK}`;

  useEffect(() => {
    getClients();
  }, []);

  const getClients = () => {
    setLoad(true);
    const token = sessionStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "token " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    axios
      .get(url + "servicio/mape_clients", config)
      .then((response) => {
        setClients(response.data.clients);
        setLoad(false);
      })
      .catch((e) => {
        setLoad(false);
        setError(true);
        return null;
      });
  };

  const submit = (event) => {
    setLoad(true);
    event.preventDefault();
    const token = sessionStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "token " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    axios
      .post(url + "servicio/mape_clients", values, config)
      .then((response) => {
        setData(response.data.data);
        setEvents(response.data.events);
        setMape(response.data.general)
        setLoad(false);
      })
      .catch((e) => {
        setLoad(false);
        setError(true);
        return null;
      });
  };

  function onChange(event, type) {
    let v = values;
    if (Object.keys(values).includes(type)) {
      v[type] = event.target.value;
    } else {
      v[type] = event.target.value;
    }
    setValues(v);
  }

  function onChangeCal(event, type) {
    let v = values;
    v[type] = dayjs(event.$d.toISOString()).format("YYYY-MM-DD")
    if(type == "fecha-inicio")
      v['dateI'] = dayjs(event.$d.toISOString())
    if(type == "fecha-fin")
      v['dateF'] = dayjs(event.$d.toISOString())
    setValues(v);
  }

  function Form() {
    return (
      <form style={{ display: "flex", alignItems: "center" }} onSubmit={submit}>
        <FormControl style={formStyle}>
          <FormLabel>Clientes:</FormLabel>
          <Select defaultValue={values.client} onChange={(e) => onChange(e, "client")}>
            <MenuItem key={-1} value={-1}>
              Selecciona una opción{" "}
            </MenuItem>
            {clients.map((item, index) => (
              <MenuItem key={index} value={item["nombre"]}>
                {item["nombre"]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl style={formStyle}>
          <FormLabel>Fecha inicial:</FormLabel>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="es-mx"
          >
            <DatePicker
            defaultValue={values.dateI}
              format="YYYY-MM-DD"
              onChange={(e) => onChangeCal(e, "fecha-inicio")}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl style={formStyle}>
          <FormLabel>Fecha final:</FormLabel>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="es-mx"
          >
            <DatePicker
              defaultValue={values.dateF}
              format="YYYY-MM-DD"
              onChange={(e) => onChangeCal(e, "fecha-fin")}
            />
          </LocalizationProvider>
        </FormControl>
        
        <FormControl>
          <br/>
          <Button
            color="primary"
            type="submit"
            variant="outlined"
            sx={{ height: "50px" }}
          >
            Calcular
          </Button>
        </FormControl>
      </form>
    );
  }

  function Graph() {
    return (
      <div>
        <ResponsiveContainer height={500}>
          <ComposedChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="hora" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line name="Mwh" type="monotone" dataKey="mwh" stroke="#FF0000"  dot={false} />
            <Line
              name="Pronostico"
              type="monotone"
              dataKey="pronos"
              stroke="#0000ff"
              dot={false}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  }

  function Table() {
    return (
      <div style={{ height: 500, width: "100%" }}>
        <DataGrid
          rows={data}
          columns={columns}
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </div>
    );
  }

  function Table_General() {
    return (
      <div style={{ height: 200, width: "100%" }}>
        <DataGrid
          rows={mape}
          columns={general}
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </div>
    );
  }

  function TableEvents() {
    return (
      <div style={{ height: 500, width: "100%" }}>
        <DataGrid
          rows={events}
          columns={columns_e}
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </div>
    );
  }

  return (
    <>
      {auth ? (
        <>
          <Box
            sx={{
              margin: "10px",
              padding: "0 10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Link href="/">
                <IconButton>
                  <ArrowBackIcon />
                </IconButton>
              </Link>
              <h1>Mape clientes/cargas</h1>
            </Box>

            <Form />
            <Graph />
            <h1>Tabla pronosticos</h1>
            <Table />
            <h1>Mape</h1>
            <Table_General/>
            <h1>Tabla eventos</h1>
            <TableEvents />
            <LoadingModal isLoad={load} />
            <ErrorModal open={error} close={handleClose} />
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              margin: "10px",
              padding: "0 10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Link href="/">
                <IconButton>
                  <ArrowBackIcon />
                </IconButton>
              </Link>
              <h1>Inicia Sesión</h1>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
